<template>
    <div>
        <el-form :model="formData" ref="addEditForm" label-width="120px">
            <el-form-item label="品牌名称：" prop="brandName" :rules="[{required:true,message:'品牌名称不能为空',trigger: 'blur'}]">
                <el-input v-model="formData.brandName" placeholder="请输入品牌名称，最多输入20个字" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="商品供应商：" prop="supplierId" :rules="[{required:true,message:'商品供应商不能为空',trigger: 'blur'}]">
                <el-select v-model="formData.supplierId" placeholder="请选择商品供应商" filterable clearable>
                    <el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="品牌简介：" prop="brandViceTitle">
                <el-input class="width240" v-model="formData.brandViceTitle" placeholder="请输入品牌副标题，最多输入20个字" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item
                label="品牌链接："
                prop="linkUrl"
                :rules="[{required:true,message:'品牌链接不能为空',trigger: 'blur'}]"
            >
                <div class="link-modal-info" @click="showModal()">
                    <div class="link-info" v-if="!formData.linkUrl.data">
                        <div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
                        <div class="icon" style="background: transparent;">
                            <i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
                        </div>
                    </div>
                    <div class="link-info" v-else>
                        <div class="cont">{{formData.linkUrl.text}}</div>
                        <div class="icon flex center">
                            <i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
                            <div style="margin-left: 10px;font-size:14px" @click.stop="formData.linkUrl = {}">
                                x
                            </div>
                        </div>
                        
                    </div>
                </div>
            </el-form-item>
            <el-form-item
                label="品牌LOGO："
                :inline-message="true"
                prop="brandLogo"
                :rules="[{required:true,message:'LOGO不能为空',trigger: 'blur'}]"
                class="container"
            >
                <el-upload
                    class="avatar-uploader"
                    :action="imgApi"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                >
                    <img
                        v-if="formData.brandLogo"
                        :src="formData.brandLogo+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
                        style="width:146px;height:146px;"
                        class="avatar"
                    />
                    <i
                        v-else
                        class="el-icon-plus"
                        style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"
                    ></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="品牌介绍：" prop="brandIntroduce">
                <el-input v-model="formData.brandIntroduce" :rows="4" type="textarea" placeholder="请输入品牌简介"></el-input>
            </el-form-item>
            <div style="text-align:right;">
                <el-button @click="cancelAddEdit">取消</el-button>
                <el-button type="primary" @click="saveAddEdit">确认</el-button>
            </div>
        </el-form>
        <!-- 选择链接弹窗 -->
        <link-modal 
            :showLiveTab="true" 
            :visible="shopVisible" 
            :showModal='false'
            @change="dataChange" 
            @cancel="shopVisible=false">
        </link-modal>
    </div>
</template>

<script>
    import { 
        addBrand, 
        updateBrand 
    } from "@/api/goods";
    import config from '@/config/index'
    import linkModal from '@/views/components/linkModal';
	export default {
        components: {
            linkModal
        },
		props: {
			formData: {
				type: Object,
				default: null,
			},
            supplierList:{
                type: Array
            }
		},
		data() {
			return {
				name: '',
                imgApi: config.UPLOAD_IMG,
                shopVisible:false,  //链接弹窗开关
			}
		},
		methods: {
			//取消
			cancelAddEdit() {
				this.$refs["addEditForm"].clearValidate();
				this.$emit('closeLayer', false);
			},
			// 上传logo成功钩子
            handleAvatarSuccess (res) {
                this.formData.brandLogo = res.data[0];
                return true;
            },
            //链接弹窗
            showModal(){
                this.shopVisible = true;
            },
            //链接变更
            dataChange(record){
                this.formData.linkUrl = record;
            },
            // 上传图前钩子
            beforeAvatarUpload (file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
                const isLt3M = file.size / 1024 / 1024 < 3;
                if (!isJPG) {
                    this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
                }
                if (!isLt3M) {
                    this.$message.error('图片大小请控制在3M以内!');
                }
                return isJPG && isLt3M;
            },
			//确认提交
			async saveAddEdit() {
				let params = { ...this.formData};
                params = JSON.parse(JSON.stringify(params));
                params.linkUrl = JSON.stringify(params.linkUrl);
                console.log(params.linkUrl)
                if (!params.linkUrl || params.linkUrl == "{}") {
                    this.$message({
                        type: 'error',
                        message: '请选择品牌链接'
                    });
                    return;
                }
				let res = {};
				if(params.id) {
					res = await updateBrand(params);
				} else {
					res = await addBrand(params);
				}
				if(res.success) {
					this.$emit('closeLayer', true);
				}else{
                    this.$message({
                        type: 'error',
                        message: res.alertMsg
                    });
                }
			},
		}
	}
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}
}
</style>