<template>
	<div class="main-content">
		<el-form inline size="small" label-width="100px">
			<el-form-item>
				<el-button type="primary" @click="addItem">新增品牌</el-button>
			</el-form-item>
			<el-form-item label="品牌名称：">
				<el-input class="input-width" v-model="filter.brandName" placeholder="请输入品牌名称搜索"></el-input>
			</el-form-item>
			<el-form-item label="品牌ID：">
				<el-input class="input-width" v-model="filter.id" placeholder="请输入品牌ID搜索"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="filter.list" v-loading="tableLoading" border>
			<el-table-column prop="id" label="品牌ID" width="100"></el-table-column>
			<el-table-column prop="brandLogo" label="品牌LOGO" width="100">
				<template slot-scope="scope">
					<div class="iv-heaer">
						<img v-if="scope.row.brandLogo" :src="scope.row.brandLogo" alt="" />
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="brandName" label="品牌名称" width="160"></el-table-column>
			<el-table-column prop="brandViceTitle" label="品牌简介"></el-table-column>
			<el-table-column prop="brandIntroduce" label="品牌介绍"></el-table-column>
            <el-table-column label="归属供应商" width="100">
				<template slot-scope="scope">
					<div class="iv-heaer" v-for="(supplier,index) in supplierList" :key='index'>
						<div v-if="supplier.id == scope.row.supplierId">
                            {{supplier.supplierName}}
                        </div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="100"></el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
					<el-button type="text" class="pointers" @click="editItem(scope)">编辑</el-button>
					<el-button type="text" class="pointers" @click="deleteItem(scope)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination 
            style="text-align: right; padding: 15px" 
            :current-page="filter.pageNo" 
            :page-size="filter.pageSize"
			:total="filter.total" 
            :page-sizes="filter.sizes" 
            @size-change="pageSizeChange"
			@current-change="currentChange" 
            background layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

		<!--  -->
		<el-dialog :title="addEditTitle" :visible.sync="addEditFlag" width="800px" @closed="clearDataFunc">
			<AddEdit v-if="addEditData" :formData="addEditData" @closeLayer="closedLayer" :supplierList='supplierList'></AddEdit>
		</el-dialog>
	</div>
</template>

<script>
	import { 
        findBrandPage, 
        supplierGetList,
        delBrand
    } from '@/api/goods';
	import AddEdit from './addEdit';
	export default {
		components: {
			AddEdit,
		},
		data() {
			return {
				tableLoading: false,
				filter: {
					brandId: '',
					brandName: '',
					pageNo: 1,
					pageSize: 10,
					total: 0,
					list: [],
				},
				addEditTitle: '新增品牌',
				addEditFlag: false,
				addEditData: null,
                supplierList:[],    //供应商列表
			}
		},
		mounted() {
            this.getList();
			this.getSearhInfor();
		},
		methods: {
            //获取筛选数据
			async getSearhInfor() {
				try {
					//获取供应商
					let result = await supplierGetList({
						pageSize: 10000,
						pageNo: 0
					})
                    this.supplierList = result.data.records;
				} catch (e) {
					console.log(e)
				}
			},
			search() {
				this.filter.pageNo = 1;
				this.getList();
			},
			pageSizeChange(size) {
				this.filter.pageSize = size;
				this.getList();
			},
			currentChange(page) {
				this.filter.pageNo = page;
				this.getList();
			},
			// 获取列表
			async getList() {
				this.tableLoading = true;
				let params = {
					id: this.filter.id,
					brandName: this.filter.brandName,
					pageNo: this.filter.pageNo,
					pageSize: this.filter.pageSize,
				};
				let { success, data } = await findBrandPage(params);
				if (success) {
					this.filter.list = data.records || [];
					this.filter.total = data.total;
				}
				setTimeout(() => {
					this.tableLoading = false;
				}, 300);
			},
			addItem() {
				this.addEditFlag = true;
				this.addEditTitle = '新增品牌';
				this.addEditData = {
					brandName: '',
					brandViceTitle: '',
					brandLogo: null,
					brandIntroduce: null,
                    supplierId:null,
                    linkUrl:{}
				};
			},
			//编辑/新增品牌
			editItem({row}) {
				this.addEditTitle = '编辑品牌';
                let temp = JSON.parse(JSON.stringify(row));
                temp.linkUrl = JSON.parse(temp.linkUrl);
				this.addEditData = {...temp};
                console.log(this.addEditData);
                this.addEditFlag = true;
			},
			//删除品牌
			async deleteItem({row}) {
                this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {}).then(async () => {
                    let result = await delBrand({id:row.id});
                    if (result.success) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList();
                        
                    } else {
                        this.$message({
                            type: 'error',
                            message: result.alertMsg
                        });
                    }
                })
			},
			//关闭弹出层
			closedLayer(flag) {
				this.addEditFlag = false;
				if(flag) {
					this.getList();
				}
			},
			clearDataFunc() {
				this.addEditData = null;
			},
		}
	}
</script>

<style scoped lang="less">
	.iv-heaer>img {
		width: 50px;
		height: 50px;
	}
</style>